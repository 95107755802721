<template>
    <a class="icon iconButton" @click.prevent="$router.push('/notification')">
        <img src="@/assets/icon/bell.svg" />
        <div class="new">
            <!-- <span class="fXS white">{{ countUnread }}</span> -->
            <span class="fXS white">{{ unreadCount }}</span>
        </div>
    </a>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

// import { db } from "../db.js";

export default {
    name: "NotificationBell",
    data() {
        return {
            // countUnread: 0,
            // apiHost: process.env.VUE_APP_API_HOST,
            // merchantId: process.env.VUE_APP_MERCHANT_ID
        };
    },
    computed: {
        ...mapState([ 'user', 'userInfo' ]),
        ...mapGetters({
            notifications: 'noti/notifications',
            unreadCount: 'noti/unreadCount'
        }),
    },
    mounted() {

    },
    methods: {
        // countUnreadNotification() {

        //     // 從Vuex module "noti"直接拿取notifications並算出通知數
        //     this.countUnread = Object.values(this.notifications)
        //         .filter(noti => noti.unread === true)
        //         .length;
        //     console.log('<DEBUG> countUnreadNotification', this.countUnread, this.notifications);
        //     this.$emit('count-unread', this.countUnread);

        //     // // Obtain the latest read timestamp first.
        //     // let latestReadAt;
        //     // db.collection(`notifications/${this.merchantId}/recipients/${this.user.userId}/readTopics`)
        //     // .doc(this.user.user.firestoreChannelId)
        //     // .get()
        //     // .then(snapshot => {
        //     //     if (!snapshot.exists)
        //     //         latestReadAt = 0;
        //     //     else
        //     //         latestReadAt = snapshot.data().latestReadAt;
                
        //     //     // Preceed to get a snapshot of notification messages.
        //     //     db.collection(`notifications/${this.merchantId}/topics/${this.user.user.firestoreChannelId}/messages`)
        //     //     .where('ts', '>', latestReadAt)
        //     //     .where('unread', '==', true)
        //     //     .onSnapshot(snapshot => {
        //     //         this.countUnread = snapshot.docs.length;
        //     //         this.$emit('count-unread', this.countUnread);
        //     //     });
        //     // });

        //     // let collection = db.collection(
        //     //     `notifications/${this.merchantId}/topics/${this.user.user.firestoreChannelId}/messages`
        //     // );
        //     // collection.where("unread", "==", true).onSnapshot((snapshot) => {
        //     //     this.countUnread = snapshot.docs.length;
        //     //     this.$emit("count-unread", this.countUnread);
        //     // });
        //     // collection
        //     //     .where("unread", "==", true)
        //     //     // .where("subtype", "==", "points-reward")
        //     //     .orderBy("ts", "desc")
        //     //     .limit(1)
        //     //     .onSnapshot((snapshot) => {

        //     //         //NOTE: Howard's fix: 除得點以外的通知也應該show popup
        //     //         snapshot.forEach((doc) => {
        //     //             const data = doc.data();
                        
        //     //             if (data.getNotify)
        //     //                 return;
                        
        //     //             console.log(`countUnreadNotification: got a new notification`, data);

        //     //             if (data.subtype == 'points-reward') {
        //     //                 this.$emit(
        //     //                     "get-point",
        //     //                     Object.assign({}, doc.data(), { docId: doc.id })
        //     //                 );
        //     //             }
        //     //             else if (data.subtype == 'collect-stamps') {
        //     //                 this.$notify({
        //     //                     group: 'noti-popup',
        //     //                     title: data.title,
        //     //                     text: data.text
        //     //                 });
        //     //             }
        //     //             else if (data.subtype == 'redeem-reward') {
        //     //                 this.$notify({
        //     //                     group: 'noti-popup',
        //     //                     title: data.title,
        //     //                     text: data.text
        //     //                 });
        //     //             }

        //     //             // if(!data.getNotify) {
        //     //             //     this.$emit(
        //     //             //         "get-point",
        //     //             //         Object.assign({}, doc.data(), { docId: doc.id })
        //     //             //     );
        //     //             // }
        //     //         });
        //     //     });
        // }
    }
};
</script>

